body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-appear,
.fade-enter {
opacity: 0;
z-index: 1;
transform: translateY(200%);
}
.fade-appear-active,
.fade-enter.fade-enter-active {
opacity: 1;
transition: all 300ms ease-in-out;
transition-delay: 100ms;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 0;
transition: all 300ms ease-in-out;
transition-delay: 100ms;
transform: translateY(500%);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cousine:wght@400;700&display=swap');